<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import activitySvc from "@/services/activity";

export default {
  page: {
    title: "Activity Detail",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Activity",
      items: [
        {
          text: "Activity",
          href: "../activity",
        },
        {
          text: "Activity Detail",
          active: true,
        },
      ],
      activity: {
        nameTh: "",
        nameEn: "",
        activityType: "",
        actTypeId: "",
        activityDetailTh: "",
        activityDetailEn: "",
        touristTarget: "",
        touristInterested: "",
        activityMarketingDetail: "",
        operation: "",
        activityLevel: "",
        activityBudget: "",
        activityAnnounce: "",
        activityAnnual: "",
        startDate: "",
        endDate: "",
        timeDetail: "",
        objective: "",
        history: "",
        sourceData: "",
        maxGuest: "",
        region: "",
        province: "",
        district: "",
        regionId: "",
        provinceId: "",
        districtId: "",
        location: "",
        venue: "",
        contact: "",
        website: "",
        facebook: "",
        instargram: "",
        lineId: "",
        tiktok: "",
        youtube: "",
        email: "",
        bookingDetail: "",
        remark: "",
        thaiAdultFee: "",
        thaiChildFee: "",
        foreignerAdultFee: "",
        foreignerChildFee: "",
        createdDate: "",
        updatedDate: "",
      },
    };
  },
  mounted() {
    this.get();
    // this.$route.query.uuid
  },
  methods: {
    get() {
      activitySvc.getDetail(this.$route.query.id).then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.activity = resp.data.data;
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="`${title} (${activity.nameTh})`" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3 position-relative">
                <label>Name (TH)</label>
                <input
                  v-model="activity.nameTh"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3 position-relative">
                <label>Name (EN)</label>
                <input
                  v-model="activity.nameEn"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Activity Type</label>
                <input
                  v-model="activity.activityType"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Activity Level</label>
                <input
                  v-model="activity.activityLevel"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Activity Budget</label>
                <input
                  v-model="activity.activityBudget"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Activity Announce</label>
                <input
                  v-model="activity.activityAnnounce"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Activity Annual</label>
                <input
                  v-model="activity.activityAnnual"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3 position-relative">
                <label>Activity Detail (TH)</label>
                <textarea
                  v-model="activity.activityDetailTh"
                  class="form-control"
                  readonly
                  row
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3 position-relative">
                <label>Activity Detail (EN)</label>
                <textarea
                  v-model="activity.activityDetailEn"
                  class="form-control"
                  readonly
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-md-8">
              <div class="mb-3 position-relative">
                <label>Activity Marketing Detail</label>
                <textarea
                  v-model="activity.activityMarketingDetail"
                  class="form-control"
                  readonly
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Tourist Target</label>
                <input
                  v-model="activity.touristTarget"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Tourist Interested</label>
                <input
                  v-model="activity.touristInterested"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Operation</label>
                <input
                  v-model="activity.operation"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Start Date</label>
                <input
                  v-model="activity.startDate"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>End Date</label>
                <input
                  v-model="activity.endDate"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Time Detail</label>
                <input
                  v-model="activity.timeDetail"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Objective</label>
                <input
                  v-model="activity.objective"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>History</label>
                <input
                  v-model="activity.history"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Source Data</label>
                <input
                  v-model="activity.sourceData"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Max Guest</label>
                <input
                  v-model="activity.maxGuest"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Region</label>
                <input
                  v-model="activity.region"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Location</label>
                <input
                  v-model="activity.location"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>District</label>
                <input
                  v-model="activity.district"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Province</label>
                <input
                  v-model="activity.province"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Venue</label>
                <input
                  v-model="activity.venue"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Contact</label>
                <input
                  v-model="activity.contact"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Website</label>
                <input
                  v-model="activity.website"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Facebook</label>
                <input
                  v-model="activity.facebook"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Instargram</label>
                <input
                  v-model="activity.instargram"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Line ID</label>
                <input
                  v-model="activity.lineId"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Tiktok</label>
                <input
                  v-model="activity.tiktok"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>youtube</label>
                <input
                  v-model="activity.youtube"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>email</label>
                <input
                  v-model="activity.email"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Booking Detail</label>
                <input
                  v-model="activity.bookingDetail"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Remark</label>
                <input
                  v-model="activity.remark"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Thai Adult Fee</label>
                <input
                  v-model="activity.thaiAdultFee"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Thai Child Fee</label>
                <input
                  v-model="activity.thaiChildFee"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Foreigner Adult Fee</label>
                <input
                  v-model="activity.foreignerAdultFee"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Foreigner Child Fee</label>
                <input
                  v-model="activity.foreignerChildFee"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <!--div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Created Date</label>
                <input
                  v-model="activity.createdDate"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label>Updated Date</label>
                <input
                  v-model="activity.updatedDate"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
