import axios from "./index";

export default {
  getAll(page, perPage, keywordSearch = "", sortBy = "id", sortType = "asc") {
    return axios.get(
      `/v1/tat/activity?page=${page}&perPage=${perPage}&keywordSearch=${keywordSearch}&sortBy=${sortBy}&sortType=${sortType}`
    );
  },
  getDetail(id) {
    return axios.get(`/v1/tat/activity/${id}`);
  },
};
